import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { UAParser } from 'ua-parser-js'
import './analytics.txt'
import './healthcheck.txt'
import { ADMIN_CISCO_CONFIG_URL } from './App/ApplicationManagement/CiscoConfiguration/ciscoConfig.constants'
import { ADMIN_CISCO_WEBEX_CONFIG_URL } from './App/ApplicationManagement/CiscoWebexConfiguration/ciscoWebexConfig.constants'


import React from 'react'
import ReactDOM from 'react-dom'
import * as ReactRouterDOM from 'react-router-dom'
import * as jsxRuntime from 'react/jsx-runtime'
import './index.css'

const getDesktopApplication = () => import('./bootstrap')
const getMobileApplication = () => (location.href = '/mobile/')
const parser = new UAParser()
const result = parser.getResult()
const urlParams = new URLSearchParams(location.search)

const route = urlParams.get('router')
const code = urlParams.get('code')

// put on constant
if (route === `${ADMIN_CISCO_CONFIG_URL}`) {
  window.location.replace(`https://${window.location.host}/#${ADMIN_CISCO_CONFIG_URL}?code=${code}`)
}

if (route === `${ADMIN_CISCO_WEBEX_CONFIG_URL}`) {
  window.location.replace(`https://${window.location.host}/#${ADMIN_CISCO_WEBEX_CONFIG_URL}?code=${code}`)
}


declare global {
  interface Window {
    SharedDependencies: {
      react: typeof React,
      reactDOM: typeof ReactDOM,
      reactRouterDOM: typeof ReactRouterDOM,
      jsxRuntime: typeof jsxRuntime,
    },
  }
}

// sharing common libraries with external libs.
// For example, `bi-qs-embedding` app depends on this!
window.SharedDependencies = {
  react: React,
  reactDOM: ReactDOM,
  reactRouterDOM: ReactRouterDOM,
  jsxRuntime,
}

if (result.device.type === 'mobile' || result.device.type === 'tablet') {
  const useMobile = confirm('Do you want to use the Calabrio ONE mobile-friendly user interface?'
      + '\n\nNote: The mobile-friendly user interface will be retired on March 31st, 2025.')
  if (useMobile) {
    getMobileApplication()
  } else {
    getDesktopApplication()
  }
} else {
  getDesktopApplication()
}
